

.cookies__modal {
    position: fixed;
    bottom: 20px;
    left: 10px;
    box-sizing: border-box;
    padding: 1rem;

    .cookies__modal-content {
        max-width: 360px; 
        margin: 0 auto; 
        padding: 12px;
        border-radius: 16px;
        border: 2px solid #1DA5AD;
        box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.03);
        background-color: #d6e2e3;
    }

    .cookies__modal-body {
        margin: 10px 0px;
    }
    .MuiDialogTitle-root {
        color: #4A5660;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .MuiDialogContent-root {
        color: #4A5660;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .link {
        cursor: pointer;
        color: #399CEE;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .cookies__modal-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .accept-button {
        width: 152px;
        padding: 8px;
        border-radius: 8px;
        background: #FFF;
        color: #4A5660;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .decline-button {
        width: 152px;
        padding: 8px;
        border-radius: 8px;
        background: #40C1A7;
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
@primary-color: #2ebb49;