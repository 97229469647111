.tc-text {
    color: #333 !important;
    text-align: left;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-top: 5px !important;
}

.tc-title {
    color: #333 !important;
    text-align: left !important;
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-top: 24px !important;
}

.tc-sub-title {
    color: #333 !important;
    text-align: left !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-top: 12px !important;
}

@primary-color: #2ebb49;