.calculator__component {
    margin: 40px 0px;

    @media (max-width: 700px) {
        margin: 20px 0px;
    }
}

.calculator__page-title {
    color: #2e2e2e;
    font-family: Poppins;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    padding: 0px 14%;

    @media (max-width: 700px) {
        font-size: 24px;
        padding: 0px 5%;
    }
}

.calculator__title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    @media (max-width: 700px) {
        font-size: 18px;
    }
}

.calculator__text-title {
    color: #18191f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding: 0px;
    margin: 20px 0px 0px;

    @media (max-width: 700px) {
        font-size: 16px;
    }
}

.text-accent {
    color: #85bb65 !important;
    font-weight: 700;
}

.calculator__text {
    color: #18191f;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 0px;
    margin: 0px;

    @media (max-width: 700px) {
        font-size: 16px;
    }
}

.calculator__dish-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.calculator__dish-name-text {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-right: 12px;

    @media (max-width: 700px) {
        font-size: 14px;
        margin-right: 0;
    }
}

.calculator__dish-name-input {
    color: #000;
    width: 100%;
    max-width: 700px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-right: 12px;

    @media (max-width: 700px) {
        font-size: 14px;
        margin-right: 0;
    }
}

.calculator__container {
    border-radius: 8px;
    border: 1px solid #000;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    padding: 40px;

    @media (max-width: 700px) {
        margin-top: 20px;
        padding: 20px;
    }
}

.calculator__filter-option {
    cursor: pointer;
}

.calculator__input {
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.calculator__dropdown {
    z-index: 5;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    border: 1px solid #ccc;
    max-height: 450px;
    overflow-y: scroll;
    position: absolute;
    background-color: white;
    left: 104px;
    top: 31px;
    border-radius: 8px;
    max-width: 579px;

    @media (max-width: 700px) {
        left: 10px;
        top: 25px;
        max-width: 90%;
    }
}

.calculator__dropdown-item {
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.calculator__selected {
    margin-top: 15px;
}

.calculator__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.calculator__switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.calculator__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.calculator__slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.calculator__switch input:checked + .calculator__slider {
    background-color: #2196f3;
}

.calculator__switch input:checked + .calculator__slider:before {
    transform: translateX(26px);
}

.calculator__ingredient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px;
    position: relative;
    width: 100%;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.calculator__ingredient-left,
.calculator__ingredient-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.calculator__ingredient-left {
    width: 100%
}

.calculator__ingredient-text {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 0px;
    margin: 0px 20px 0px 0px;

    @media (max-width: 700px) {
        font-size: 14px;
        margin: 0 10px 10px 0;
    }
}
.min-width-72 {
    min-width: 72px;
}

.calculator__ingredient-name-input {
    height: 40px;
    color: #000;
    font-family: Poppins;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px 5px 0px 0px;

    @media (max-width: 700px) {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
}

.calculator__ingredient-amount-input {
    height: 40px;
    color: #000;
    width: 60px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px 5px 0px 0px;
    text-align: right;

    @media (max-width: 700px) {
        width: 100%;
        text-align: left;
        margin: 0 0 10px 0;
    }
}

.calculator__add-button-wrapper {
    margin: 20px 0px 0px 20px;

    @media (max-width: 700px) {
        margin: 10px 0 0 0;
        width: 100%;
        text-align: center;
    }
}

.calculator__add-button {
    width: 170px;
    height: 30px;
    padding-top: 7px !important;
    border-radius: 12px !important;
    background: #85bb65 !important;
    color: #fff !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    text-transform: none !important;

    @media (max-width: 700px) {
        width: 100%;
    }
}

.calculator__dropdown-settings {
    cursor: help;
    display: flex;
    flex-direction: row;
    margin-top: 60px;

    @media (max-width: 700px) {
        flex-direction: column;
        margin-top: 20px;
    }
}
.calculator__dropdown-settings-text {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
    padding: 0px;

    @media (max-width: 700px) {
        font-size: 12px;
    }
}

.calculator__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
    }
}

.calculator__proceed-button {
    height: 50px;
    padding: 24px;
    min-width: 105px !important;
    align-items: center;
    color: #fff !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    border-radius: 12px !important;
    text-transform: none !important;

    @media (max-width: 700px) {
        width: 100%;
        padding: 16px;
        font-size: 12px !important;
    }
}

.calculator__ingredient-tooltip-icon {
    display: flex;
    align-items: flex-start;
    height: 30px;
    cursor: help;
    margin-right: 20px;

    @media (max-width: 700px) {
        margin-right: 10px;
    }
}

.calculator__ingredient-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
        flex-direction: column;
    }
}

.calculator__ingredient-delete-button {
    min-width: 40px !important;

    @media (max-width: 700px) {
        min-width: 100%;
    }
}

.calculator__submit-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
    }
}

.calculator__payment-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calculator__payment-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    width: 100%;

    @media (max-width: 700px) {
        font-size: 24px;
        line-height: 40px;
    }
}

.calculator__payment-text {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100%;

    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
    }
}

.payment-plans__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
    }
}

.payment-plans__card {
    width: 299px;
    height: 400px;
    border-radius: 15px;
    border: 1.5px solid #c4c4c4;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 700px) {
        width: 100%;
        height: auto;
        padding: 15px;
    }
}

.payment-plans__card-description {
    padding: 15px;

    @media (max-width: 700px) {
        padding: 10px;
    }
}

.payment-plans__card-title {
    color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    margin: 0;

    @media (max-width: 700px) {
        font-size: 22px;
    }
}

.payment-plans__card-text {
    margin-top: 11px;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @media (max-width: 700px) {
        font-size: 10px;
    }
}

.payment-plans__card-price {
    margin-top: 28px;
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    @media (max-width: 700px) {
        font-size: 28px;
        line-height: 28px;
    }
}

.payment-plans__card-button {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: 2px solid #000;
    background: #fff;
    cursor: pointer;
    transition:
        all 0.3s ease,
        border-color 0.3s ease;

    &:hover {
        background: #808080;
        border-color: #808080;
        color: #fff;
    }

    @media (max-width: 700px) {
        height: 40px;
    }
}

.payment-plans__card-calculations-info {
    margin-top: 35px;
    color: #808080;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @media (max-width: 700px) {
        font-size: 14px;
    }
}

.how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.how-it-works__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #000;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 30px;
    border-radius: 15px;
    border: 1.5px solid #85bb65;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    margin-bottom: 66px;

    @media (max-width: 700px) {
        flex-direction: column;
        padding: 20px;
        margin-bottom: 30px;
        font-size: 20px;
    }
}

.how-it-works__item-count {
    color: #85bb65;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin: 0px 20px;

    @media (max-width: 700px) {
        font-size: 20px;
    }
}

.how-it-works__text {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin: 40px 20px;

    @media (max-width: 700px) {
        font-size: 20px;
        line-height: 28px;
        margin: 20px 10px;
    }
}

.calculator__help-text {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;

    @media (max-width: 700px) {
        font-size: 16px;
        line-height: 28px;
    }
}
@primary-color: #2ebb49;