.header__nav-button {
    margin-right: 32px;
    padding: 10px;
    text-decoration: none !important;

    @media (max-width: 700px) {
        margin-right: 8px;
        padding: 4px;
    }
}

.header__nav-button-text {
    font-family: "Inter" !important;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;

    @media (max-width: 700px) {
        font-size: 12px !important;
    }
}

.header__sign-up-button {
    padding: 8px 16px !important;
    border-radius: 28px !important;
    color: #fff !important;
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-transform: none !important;
    margin-right: 32px !important;

    @media (max-width: 700px) {
        margin-right: 8px;
        padding: 6px 12px;
        font-size: 10px !important;
    }
}

.header__login-button {
    border: 1px solid #4584ff !important;
    background: #fff !important;
    padding: 8px 16px !important;
    border-radius: 28px !important;
    text-align: center;
    font-family: "Outfit" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    text-transform: none !important;
    margin-right: 32px !important;

    @media (max-width: 700px) {
        margin-right: 8px;
        padding: 6px 12px;
        font-size: 10px !important;
    }
}

.header__logo {
    margin-right: 60px;
    width: 86px;
}

@media (max-width: 700px) {
    .header__container {
        flex-direction: column;
        align-items: center;
    }

    .header__nav-buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .header__auth-buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .header__logo {
        margin-right: 2px;
        width: 55px;
    }
}
@primary-color: #2ebb49;