.page-content-wrapper {
    margin-top: 63px;
}

.page-content {
    min-height: calc(100vh - 63px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // max-width: 1000px;
    margin: 0 auto;
}

@primary-color: #2ebb49;