.footer__wrapper {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #0080fe;
    color: white;
    text-align: center;
    box-shadow: 3px 4px 8px 8px rgba(0, 0, 0, 0.2);
}

.footer__content {
    padding: 70px 0px;
    width: 100%;
    max-width: 1000px;
    min-height: 432px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}


@media (max-width: 700px) {
    .footer__content {
        flex-direction: column;
    }
}
@primary-color: #2ebb49;