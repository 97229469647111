.search__search {
    margin: 30px auto;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 28px;

    @media (max-width: 700px) {
        padding: 20px;
        max-width: 90%;
    }
}

.search__search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
        flex-direction: column;
    }
}

.search__title {
    color: #0a65ff;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    padding: 0;
    margin: 0;

    @media (max-width: 700px) {
        font-size: 24px;
        line-height: 40px;
    }
}

.search__sub-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 0;
    margin: 0;
    margin-top: 10px;

    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
        margin-top: 20px;
    }
}

.search__input-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
}

.search__input {
    width: 400px;
    color: #808080;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.search__button {
    border-radius: 12px !important;
    height: 55px;
    width: 100px;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: none !important;

    @media (max-width: 700px) {
        width: 100%;
        margin-top: 10px !important;
    }
}

.search__error {
	color: #333;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0 auto;
	text-align: center;

    @media (max-width: 700px) {
        font-size: 14px;
        margin-bottom: 100px;
    }
}

.search__company-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 28px;

    > :not(:last-child) {
        margin-right: 70px;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        > :not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.search__company-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search__company-info-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin: 0;

    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
    }
}

.search__company-info-text {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0;

    @media (max-width: 700px) {
        font-size: 16px;
        line-height: 20px;
    }
}
@primary-color: #2ebb49;