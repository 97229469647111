.dish-card {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 700px) {
        margin-bottom: 20px;
    }
}

.dish-card__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        padding-left: 24px;
    }
}

.dish-card__header-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 190px;

    @media (max-width: 700px) {
        min-width: 100%;
        align-items: center;
        margin-bottom: 10px;
    }
}

.dish-card__header-sub-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;

    @media (max-width: 700px) {
        margin-top: 3px;
    }
}

.dish-card__header-title {
    color: #85bb65;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0px;
    padding-left: 20px;

    @media (max-width: 700px) {
        font-size: 14px;
        padding-left: 10px;
    }
}

.dish-card__header-text {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
    padding-left: 20px;

    @media (max-width: 700px) {
        font-size: 14px;
        padding-left: 10px;
    }
}

.dish-card__content-text {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;

    @media (max-width: 700px) {
        font-size: 14px;
    }
}

.dish-card__ingredients {
    padding: 0px 30px;

    @media (max-width: 700px) {
        padding: 0px 15px;
    }
}

.dish-card__ingredient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 0px 30px 0px 0px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 5px 0px;
        padding: 0px 15px 0px 0px;
    }
}

.dish-card__gram {
    text-align: right;
    min-width: 60px;
    align-self: end;

    @media (max-width: 700px) {
        text-align: left;
        min-width: auto;
    }
}

.dish-card__ingredient-total {
    text-align: end;
    padding: 0px 30px 0px 0px;

    @media (max-width: 700px) {
        text-align: left;
        padding: 0px 15px 0px 0px;
    }
}

.dish-card__nutrients {
    padding: 0px 30px;

    @media (max-width: 700px) {
        padding: 0px 15px;
    }
}

.dish-card__nutrients-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
    }
}

.dish-card__nutrients-list {
    margin: 0px;
    padding: 0px !important;

    @media (max-width: 700px) {
        width: 100%;
    }
}

.dish-card__nutrient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 320px;
    margin: 10px 0px;
    padding: 0px;

    @media (max-width: 700px) {
        align-items: flex-start;
        min-width: auto;
        margin: 5px 0px;
    }
}
@primary-color: #2ebb49;