.profile__form-container {
	display: flex;
    flex-direction: column;
    max-width: 328px;
    margin: 30px auto 100px;
}

.profile__form-title {
	color: #0A65FF;
	text-align: center;
	font-family: Inter;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 60px;
	margin: 0px;
}

.profile__button {
	font-family: Inter !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	text-transform: inherit !important;
}

.profile__input {
	margin-top: 24px !important;
}

.profile__text {
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.profile__calculations-row {
	margin-top: 24px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.profile__calculations-refresh {
    border: 1px solid #00000054;
    border-radius: 35%;
    margin-left: 5px;
	cursor: pointer;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@primary-color: #2ebb49;