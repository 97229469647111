.auth__form-container {
    display: flex;
    flex-direction: column;
    max-width: 328px;
    margin: 100px auto 0px;

	@media (max-width: 700px) {
		margin: 20px auto 0px;
	}
}

.auth__title {
	color: #111;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}

.auth__form {
	margin-top: 24px;
	padding: 24px;
	@media (max-width: 700px) {
		margin-top: 0px;
	}
}

.auth__link-text {
	color: #007AFF;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration: none;
}

.auth__reset {
	color: #007AFF;
	cursor: pointer;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.auth__text {
	margin-top: 24px;
	color: #111;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.auth__button {
	font-family: Inter !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	text-transform: inherit !important;
}

.auth__modal-box {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .auth__modal-box {
        width: 95%;
        padding: 15px;
    }
}

@primary-color: #2ebb49;