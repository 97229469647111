.my-dishes__container {
    display: flex;
    flex-direction: column;
    margin: 30px auto 100px;

    @media (max-width: 700px) {
        margin: 20px 10px 50px;
    }
}

.my-dishes__title {
    color: #0a65ff;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    margin: 0px;

    @media (max-width: 700px) {
        font-size: 24px;
        line-height: 40px;
    }
}

.my-dishes__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 55px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }
}

.my-dishes__header-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin: 5px;

    @media (max-width: 700px) {
        font-size: 18px;
    }
}

.my-dishes__header-text {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    margin: 5px;

    @media (max-width: 700px) {
        font-size: 18px;
    }
}
@primary-color: #2ebb49;