.checkout__page-title {
    color: #333;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    @media (max-width: 800px) {
        font-size: 24px;
    }
}

.checkout__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 100px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 50px;
    }
}

.checkout__billing-title {
    color: #333;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 800px) {
        font-size: 18px;
    }
}

.checkout__summary {
    margin-top: 50px;

    @media (max-width: 800px) {
        margin-top: 30px;
    }
}

.checkout__summary-card-row {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }
}

.checkout__summary-card {
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    @media (max-width: 800px) {
        padding: 15px;
    }
}

.checkout__summary-card-title {
    margin: 10px 0px;
    color: #333;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 800px) {
        font-size: 20px;
    }
}

.checkout__summary-card-info {
    width: 172px;
    color: #333;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    @media (max-width: 800px) {
        width: 100%;
        font-size: 12px;
    }
}

.checkout__summary-card-plan-name {
    margin: 0;
    color: #333;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 800px) {
        font-size: 15px;
    }
}

.checkout__summary-card-plan-price {
    margin: 0;
    color: #888;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 800px) {
        font-size: 12px;
    }
}

.checkout__summary-card-total {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #333;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        font-size: 20px;
    }
}

.checkout__input {
    margin-top: 20px !important;
}

.checkout__country-code-input {
    margin-top: 20px;
    z-index: 2;

    .select__control {
        height: 56px;

        @media (max-width: 800px) {
            height: 48px;
        }
    }
}

.checkout__country-code-input-error-message {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;

    @media (max-width: 800px) {
        font-size: 0.65rem;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.checkout__summary-button-wrapper {
    margin: 130px auto 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
        margin: 70px auto 30px;
    }
}

.checkout__summary-button {
    min-width: 200px !important;
    text-transform: none !important;
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    border-radius: 12px !important;

    @media (max-width: 800px) {
        font-size: 14px !important;
    }
}
@primary-color: #2ebb49;